import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import { navigate, graphql, Link } from "gatsby"
import { Badge, Card, Col, Container, Row } from "react-bootstrap"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const Blog = ({ data }) => {
  const posts = data?.allContentfulBlogPost?.edges
  const latestPost = data?.allContentfulBlogPost?.edges[0]

  return (
    <Layout>
      <Seo title="Blog" />
      <Container
        className="text-white py-5"
        fluid
        style={{
          background:
            "repeating-linear-gradient(0deg, transparent, #d7a613 1200px)",
        }}
      >
        <Row className="justify-content-center py-5">
          <Col md="12" className="text-center">
            <h1 className="minimal-title fs-1">TawAlap Blog</h1>
            <p className="minimal-body">
              Exploring the world of Jewelry & insights into Assyrian heritage
              and innovations.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <Row>
          <Col className="text-white" md="12">
            <p className="h2 mb-3">Latest Post</p>
          </Col>
          <Col key={latestPost?.node?.id} md="12">
            <Link
              className="card bg-transparent text-white border-0 shadow d-flex flex-row text-decoration-none"
              to={`/${latestPost?.node?.slug.slug}`}
            >
              <Card.Img
                className="text-center img-fluid w-25"
                style={{
                  background:
                    "repeating-linear-gradient(245deg, transparent, #d7a613 900px)",
                }}
                src={
                  latestPost?.node?.contentBodyImages?.[0]?.url ??
                  latestPost?.node?.authorThumbnail.url
                }
              />
              <div className="w-100 d-flex flex-column py-3">
                <Card.Header className="d-flex justify-content-between align-items-center border-0 bg-transparent">
                  <Badge
                    bg="light"
                    className="small text-dark text-center text-uppercase ps-4 pe-3 py-1 position-relative"
                  >
                    <span
                      className="position-absolute top-50 translate-middle p-1 bg-warning rounded-circle"
                      style={{ left: "10px" }}
                    />
                    {latestPost?.node?.category}
                  </Badge>
                  <p className="minimal-body mb-0">
                    {latestPost?.node?.publishedDate}
                  </p>
                </Card.Header>
                <Card.Body className="border-0 bg-transparent">
                  <p className="h2 minimal-title mb-0">
                    {latestPost?.node?.title}
                  </p>
                </Card.Body>
                <Card.Footer className="d-flex align-items-center border-0 bg-transparent">
                  <GatsbyImage
                    image={getImage(latestPost?.node?.authorThumbnail)}
                    style={{ width: 35, height: 35 }}
                    className="border rounded-circle border-secondary"
                    alt="blog image"
                  />
                  <p className="mb-0 ms-2">{latestPost?.node?.author}</p>
                </Card.Footer>
              </div>
            </Link>
          </Col>
        </Row>
        <hr className="my-5" style={{ borderWidth: "1px", color: "#f2f2f2" }} />
        <Row className="pt-4">
          {posts
            ?.filter((v, index) => index > 0)
            ?.map((post, index) => (
              <Col key={post?.node?.id} className="mb-4" md="4">
                <Link
                  className="card bg-transparent text-white border-0 shadow-lg text-decoration-none h-100"
                  to={`/${post?.node?.slug.slug}`}
                >
                  <Card.Img
                    className="text-center img h-100"
                    style={{
                      background:
                        "repeating-linear-gradient(0deg, transparent, #d7a613 700px)",
                    }}
                    src={
                      post?.node?.contentBodyImages?.[0]?.url ??
                      post?.node?.authorThumbnail.url
                    }
                  />
                  <Card.Header className="d-flex justify-content-between align-items-center border-0 bg-transparent">
                    <Badge
                      bg="light"
                      className="small text-dark text-center text-uppercase ps-4 pe-3 py-1 position-relative"
                    >
                      <span
                        className="position-absolute top-50 translate-middle p-1 bg-warning rounded-circle"
                        style={{ left: "10px" }}
                      />
                      {post?.node?.category}
                    </Badge>
                    <p className="minimal-body mb-0">
                      {post?.node?.publishedDate}
                    </p>
                  </Card.Header>
                  <Card.Body className="border-0 bg-transparent">
                    <p className="h2 minimal-title mb-0">{post?.node?.title}</p>
                  </Card.Body>
                  <Card.Footer className="d-flex align-items-center border-0 bg-transparent">
                    <GatsbyImage
                      image={getImage(post?.node?.authorThumbnail)}
                      style={{ width: 35, height: 35 }}
                      className="border rounded-circle border-secondary"
                      alt="blog image"
                    />
                    <p className="mb-0 ms-2">{post?.node?.author}</p>
                  </Card.Footer>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </Layout>
  )
}
export default Blog

export const query = graphql`
  query {
    allContentfulBlogPost(filter: { node_locale: { eq: "en-US" } } sort: {fields: publishedDate, order: DESC}) {
      edges {
        node {
          id
          author
          title
          node_locale
          publishedDate(fromNow: false, formatString: "MMMM D, yyyy")
          category
          contentBodyImages {
            url
            gatsbyImageData(width: 200)
          }
          tags {
            tags {
              ref
              text
            }
            internal {
              content
            }
          }
          slug {
            slug
          }
          authorThumbnail {
            url
            gatsbyImageData(width: 200)
          }
          contentful_id
        }
      }
    }
  }
`
